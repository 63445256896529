<template>
  <div class="consensu">
    <h1>Type: TEST</h1>
    {{ configs }}
  </div>
</template>

<script>
export default {
  name: "banner-box",
  props: ["configs"]
};
</script>
